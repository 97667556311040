import { useDispatch, useSelector } from 'react-redux';
// Services
import { getIpUser } from '../services/getIpUser';
// Utils
import { currentYear } from '../utils/getYearsFilters';
// Slices
import { setSocketLibrary } from '../app/features/metasearchSlice';
import {
  setIsLibraryLoading,
  setIsLibraryLoadingMoreData,
  setLibrary,
  setLibraryKeywords,
  setLibrarySources,
  setTotalData,
  setSelectedSources,
  setSelectedKeywords,
  setPeerReviewed,
  // Data fetched
  setQueryFetched,
  setSourcesFetched,
  setKeywordsFetched,
  setStartYearFetched,
  setEndYearFetched,
  setPeerReviewedFetched,
  setPageFetched,
  setTokenPage,
  // reset variables
  resetLibrarySources,
  resetLibraryKeywords,
  resetLibrary,
  setPageShowed,
  setStartYear,
  setEndYear,
  setQueryAdvancedFetched,
} from '../app/features/librarySlice';

export const useLibrary = () => {
  const dispatch = useDispatch();

  // Slices
  const metaSearchStore = useSelector((state) => state.metasearch);
  const { user_email, ip_login } = useSelector((state) => state.user);
  const { institutionCode } = useSelector((state) => state.institution);
  const { totalData } = useSelector((state) => state.library);

  // Función para guardar las variables en Redux
  const saveInformationRedux = (
    query,
    page,
    sources,
    keywords,
    startYear,
    endYear,
    peer_reviewed,
    searchType
  ) => {
    if (page === 1) {
      dispatch(resetLibraryKeywords());
      dispatch(resetLibrary());
      dispatch(setPageShowed(1));
      dispatch(setIsLibraryLoading(true));
      dispatch(setIsLibraryLoadingMoreData(true));
    }
    // Guardando datos buscados en las variables (Fetched)
    searchType === 'advanced'
      ? dispatch(setQueryAdvancedFetched(query))
      : dispatch(setQueryFetched(query));
    dispatch(setPeerReviewedFetched(peer_reviewed));
    dispatch(setPeerReviewed(peer_reviewed));
    dispatch(resetLibrarySources());
    dispatch(setSourcesFetched(sources));
    dispatch(setSelectedSources(sources));
    dispatch(setKeywordsFetched(keywords));
    dispatch(setSelectedKeywords(keywords));
    dispatch(setStartYear(startYear));
    dispatch(setStartYearFetched(startYear));
    dispatch(setEndYear(endYear));
    dispatch(setEndYearFetched(endYear));
    dispatch(setPageFetched(page));
  };

  const getLibraryDataSocket = async (
    query = '',
    page = 1,
    peer_reviewed = false,
    startYear = 1990,
    endYear = currentYear,
    sources = [],
    keywords = [],
    searchType = false,
    pageToken = false
  ) => {
    // Controlador de conteo en respuesta Single del Websocket
    let totalLibrarySingle = 0;

    // Guarda la información de la busqueda en Redux
    saveInformationRedux(
      query,
      page,
      sources,
      keywords,
      startYear,
      endYear,
      peer_reviewed,
      searchType
    );

    const getURL = () => {
      let url = `wss://olib-api.aquinasnetwork.com/search?auth_token=${localStorage.token}`;
      // let url = `ws://localhost:8000/search?auth_token=${localStorage.token}`;
      return searchType !== 'simple' ? `${url}&version=v2` : url;
    };

    // Verifica si hay una conexión anterior y la cierra
    if (metaSearchStore && metaSearchStore.socketLibrary instanceof WebSocket) {
      metaSearchStore.socketLibrary.close();
    }

    // Se crea la configuración para la conexión al web socket
    const websocket = new WebSocket(getURL());

    // Se crea el nuevo estado para la variable "socket" que verifica si hay o no una conexión previa
    dispatch(setSocketLibrary(websocket));
    // Envia la petición al web socket para iniciar la conexión
    websocket.onopen = function () {
      const socketQuery = {
        query,
        page,
        cache: false,
        email: user_email,
        ip: ip_login ? getIpUser() : ip_login,
        institution_code: institutionCode,
        page_token: pageToken,
        params: {
          peer_reviewed,
          start_year: startYear,
          end_year: endYear,
        },
        generativeIA: searchType === 'ia' ? true : false,
        source_type: 'academic',
      };
      // Adding filters in query
      if (sources?.length > 0) {
        socketQuery.params.sources = sources;
      }
      if (keywords?.length > 0) {
        socketQuery.params.keywords = keywords;
      }
      websocket.send(JSON.stringify(socketQuery));
    };

    // Recibe la información del websocket
    websocket.onmessage = function (event) {
      const dataSource = JSON.parse(event.data);

      if (dataSource.type === 'summary') {
        if (dataSource.data.count >= 0) {
          dispatch(setTotalData(dataSource?.data?.count));
          searchType === 'advanced'
            ? dispatch(setLibraryKeywords(dataSource.data.keywords))
            : dispatch(
                setLibraryKeywords(Object.keys(dataSource.data.keywords))
              );
          dispatch(setQueryAdvancedFetched(dataSource.data.query_ia));
          dispatch(setIsLibraryLoading(false));
          dispatch(setIsLibraryLoadingMoreData(false));
          websocket.close();
        }
      }
      if (dataSource.data[Object.keys(dataSource.data)] !== undefined) {
        if (
          dataSource.data[Object.keys(dataSource.data)].results !== undefined
        ) {
          dispatch(setIsLibraryLoading(false));
          dispatch(
            setLibrarySources({
              code: Object.keys(dataSource.data)[0],
              count: dataSource.data[Object.keys(dataSource.data)].count,
            })
          );
          totalLibrarySingle =
            dataSource.data[Object.keys(dataSource.data)].count;
          dispatch(setTotalData(totalLibrarySingle));

          let count =
            totalData + dataSource.data[Object.keys(dataSource.data)].count;
          dispatch(setTotalData(count));

          dataSource.data[Object.keys(dataSource.data)].page_token &&
            dispatch(
              setTokenPage(
                dataSource.data[Object.keys(dataSource.data)].page_token
              )
            );
          dataSource.data[Object.keys(dataSource.data)].global_count &&
            dispatch(
              setTotalData(
                dataSource.data[Object.keys(dataSource.data)].global_count
              )
            );
          dispatch(
            setLibrary([
              ...dataSource.data[Object.keys(dataSource.data)].results,
            ])
          );
        }
      }
    };

    // Cierre de la conexión por tiempo
    setTimeout(() => {
      dispatch(setIsLibraryLoading(false));
      dispatch(setIsLibraryLoadingMoreData(false));
      websocket.close();
    }, 120000);
  };
  return { getLibraryDataSocket };
};
