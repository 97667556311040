// Utils
import { fetchWithoutToken } from '../utils/fetch';
import { formatDate } from '../utils/formatDate';
import { getEmailToSendInformation } from '../utils/getEmailToSendInformation';
// Service
import { getIpUser } from './getIpUser';

// Function to send user information to storage in data base
export const sendUserInformation = async (
  searchResourceCategory, // Category to search (Library, media resource, news, dictionary )
  searchedTerm, // Searched Term
  email, // email
  institutionCode
) => {
  const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
  if (ENVIRONMENT === 'PRODUCTION') {
    const date = new Date();
    const ip = await getIpUser();

    try {
      await fetchWithoutToken.post(
        '/logs',
        {
          resource_type: searchResourceCategory.slice(0, 250),
          data: searchedTerm.slice(0, 250),
          email: getEmailToSendInformation(email, institutionCode),
          ip,
          institution_code: institutionCode,
          date: formatDate(date),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      );
    } catch (e) {
      console.error(e);
    }
  } else {
    return null;
  }
};
