import { createSlice } from '@reduxjs/toolkit';
import { currentYear } from '../../utils/getYearsFilters';

const initialState = {
  patents: [],
  allPatentsSources: [],
  queryPatents: '',
  totalPatents: 0,
  isLoadingPatents: false,
  isLoadingMoreData: false,
  isPatentsDataFetched: false,
  selectedSourcesPatents: [],
  pagePatents: 1,
  startYear: 1990,
  endYear: currentYear,
  numPatents: 10,
  filters: {
    queryFetched: '',
    sourcesPatentsFetched: [],
    startYearFetched: '',
    endYearFetched: '',
    pageFetched: 1,
  },
};

const patentsSlice = createSlice({
  name: 'patents',
  initialState,
  reducers: {
    setPatents: (state, action) => {
      state.patents = [...state.patents, ...action.payload];
    },
    setAllPatentsSources: (state, action) => {
      state.allPatentsSources = action.payload;
    },
    setQueryPatents: (state, action) => {
      state.queryPatents = action.payload;
    },
    setPagePatents: (state, action) => {
      state.pagePatents = action.payload;
    },
    setNumPatents: (state, action) => {
      state.numPatents = action.payload;
    },
    setTotalPatents: (state, action) => {
      state.totalPatents = action.payload;
    },
    setIsLoadingPatents: (state, action) => {
      state.isLoadingPatents = action.payload;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload;
    },
    setIsPatentsDataFetched: (state, action) => {
      state.isPatentsDataFetched = action.payload;
    },
    setSelectedSourcesPatents: (state, action) => {
      state.selectedSourcesPatents = action.payload;
    },
    setStartYear: (state, action) => {
      state.startYear = action.payload;
    },
    setEndYear: (state, action) => {
      state.endYear = action.payload;
    },
    resetPatents: (state) => {
      state.patents = [];
      state.totalPatents = 0;
      state.numPatents = 10;
      state.pagePatents = 1;
      state.startYear = 1990;
      state.endYear = currentYear;
      state.isLoadingPatents = false;
      state.isLoadingMoreData = false;
      state.isPatentsDataFetched = false;
    },

    // Filtros para datos buscados (Fetched)
    setQueryFetched: (state, action) => {
      state.filters.queryFetched = action.payload;
    },
    setSourcesFetchedPatents: (state, action) => {
      state.filters.sourcesPatentsFetched = action.payload;
    },
    setStartYearFetched: (state, action) => {
      state.filters.startYearFetched = action.payload;
    },
    setEndYearFetched: (state, action) => {
      state.filters.endYearFetched = action.payload;
    },
    setPagePatentsFetched: (state, action) => {
      state.filters.pagePatentsFetched = action.payload;
    },
    resetPatentsFilters: (state) => {
      state.filters.pagePatents = 1;
      state.filters.startYear = 1990;
      state.filters.endYear = currentYear;
      state.filters.startYearFetched = 1990;
      state.filters.endYearFetched = currentYear;
      state.filters.pagePatentsFetched = 1;
    },
  },
});

export const {
  setPatents,
  setIsPatentsDataFetched,
  setQueryPatents,
  setAllPatentsSources,
  setPagePatents,
  setTotalPatents,
  setIsLoadingPatents,
  setIsLoadingMoreData,
  setSelectedSourcesPatents,
  setSourcesFetchedPatents,
  resetPatents,
  setStartYear,
  setEndYear,
  setStartYearFetched,
  setEndYearFetched,
  setPagePatentsFetched,
  setNumPatents,
  resetPatentsFilters,
  setQueryFetched,
} = patentsSlice.actions;

export default patentsSlice.reducer;
