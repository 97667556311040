import React from 'react';
import { Link } from 'react-router-dom';
// Components
import Metasearcher from '../../Components/Metasearcher';
import Container from '../../Components/Container';
// Reddux
import { useSelector } from 'react-redux';
// Images
import jobs_icon from '../../Images/SearchPage/jobs_icon.svg';
import books_icon from '../../Images/SearchPage/books_icon.svg';
import library_icon from '../../Images/SearchPage/library_icon.svg';
import logoOnlineLibrary from '../../Images/logo/logo_OlibBlack.svg';
import dictionary_icon from '../../Images/SearchPage/dictionary_icon.svg';
import interestingLinks_icon from '../../Images/SearchPage/interestingLinks_icon.svg';
// Styles
import './SearchPage.css';

const SearchPage = () => {
  const { institutionCode } = useSelector((state) => state.institution);

  return (
    <main className='searchPage'>
      <Container>
        <div className='searchPageContainer'>
          <Link to='/'>
            <img
              className='searchPageContainerLogo'
              src={logoOnlineLibrary}
              alt='logo online library'
            />
          </Link>
          <section className='searchPageContainerBar'>
            <Metasearcher />
          </section>
          <section className='searchPageModules'>
            <Link to='/jobs'>
              <img src={jobs_icon} alt='jobs' />
              <p>{institutionCode === 'ILUNI' || institutionCode === 'NOUVELLE' ? 'Empleos' : 'Jobs'}</p>
            </Link>
            <Link to='/articles'>
              <img src={library_icon} alt='articles' />
              <p>{institutionCode === 'ILUNI' || institutionCode === 'NOUVELLE' ? 'Artículos' : 'Articles'}</p>
            </Link>
            <Link to='/books'>
              <img src={books_icon} alt='books' />
              <p>{institutionCode === 'ILUNI' || institutionCode === 'NOUVELLE' ? 'Libros' : 'Books'}</p>
            </Link>
            <Link to='/dictionary'>
              <img src={dictionary_icon} alt='dictionary' />
              <p>
                {institutionCode === 'ILUNI' || institutionCode === 'NOUVELLE' ? 'Dictionario' : 'Dictionary'}
              </p>
            </Link>
            <Link to='/news'>
              <img src={interestingLinks_icon} alt='interesting links' />
              <p> {institutionCode === 'ILUNI' || institutionCode === 'NOUVELLE' ? 'Noticias' : 'News'}</p>
            </Link>
          </section>
        </div>
      </Container>
    </main>
  );
};

export default SearchPage;
