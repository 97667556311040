import { createSlice } from '@reduxjs/toolkit';
import { currentYear } from '../../utils/getYearsFilters';

const initialState = {
  data: [],
  queryFetched: '',
  queryAdvancedFetched: '',
  tokenPage: false,
  searchType: 'simple',
  filters: {
    generativeIAFetched: false,
    sourcesFetched: [],
    keywordsFetched: [],
    startYearFetched: 1990,
    endYearFetched: currentYear,
    pageFetched: 1,
    peerReviewedFetched: false,
    advancedSearchFetched: false,
  },
  totalData: 0,
  peerReviewed: false,
  librarySources: [],
  allLibrarySources: [],
  selectedSources: [],
  libraryKeywords: [],
  selectedKeywords: [],
  isLibraryLoading: false,
  isLibraryLoadingMoreData: false,
  startYear: 1990,
  endYear: currentYear,
  pageShowed: 1,
};

const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    // Toda la data de Library
    setLibrary: (state, action) => {
      state.data = [...state.data, ...action.payload];
    },
    // Total de resultados de library
    setTotalData: (state, action) => {
      state.totalData = action.payload;
    },
    // Vaciar datos de library
    resetLibrary: (state) => {
      state.data = [];
      state.totalData = 0;
      state.token = false;
    },
    // Estado para loading de carga de data
    setIsLibraryLoading: (state, action) => {
      state.isLibraryLoading = action.payload;
    },
    setTokenPage: (state, action) => {
      state.tokenPage = action.payload;
    },
    setIsLibraryLoadingMoreData: (state, action) => {
      state.isLibraryLoadingMoreData = action.payload;
    },
    // Todas las bases de datos de Library
    setLibrarySources: (state, action) => {
      state.librarySources = [...state.librarySources, action.payload];
    },
    // Bases de datos seleccionadas
    setSelectedSources: (state, action) => {
      state.selectedSources = action.payload;
    },
    // Total de keywords de Library
    setLibraryKeywords: (state, action) => {
      state.libraryKeywords = action.payload;
    },
    // Keywords seleccionadas
    setSelectedKeywords: (state, action) => {
      state.selectedKeywords = action.payload;
    },
    setQueryFetched: (state, action) => {
      state.queryFetched = action.payload;
    },
    setQueryAdvancedFetched: (state, action) => {
      state.queryAdvancedFetched = action.payload;
    },
    // Busqueda por peer reviewed
    setPeerReviewed: (state, action) => {
      state.peerReviewed = action.payload;
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },
    setStartYear: (state, action) => {
      state.startYear = action.payload;
    },
    setEndYear: (state, action) => {
      state.endYear = action.payload;
    },
    setPageShowed: (state, action) => {
      state.pageShowed = action.payload;
    },
    resetLibrarySources: (state) => {
      state.librarySources = [];
    },
    resetLibraryKeywords: (state) => {
      state.libraryKeywords = [];
    },
    setAllLibrarySources: (state, action) => {
      state.allLibrarySources = action.payload;
    },

    // Reinicio de filtros
    resetFilters: (state) => {
      state.totalData = 0;
      state.selectedSources = [];
      state.selectedKeywords = [];
      state.startYear = 1990;
      state.endYear = currentYear;
      state.pageShowed = 1;
      state.peerReviewed = false;
    },

    // Filtros para datos buscados (Fetched)
    setSourcesFetched: (state, action) => {
      state.filters.sourcesFetched = action.payload;
    },
    setKeywordsFetched: (state, action) => {
      state.filters.keywordsFetched = action.payload;
    },
    setStartYearFetched: (state, action) => {
      state.filters.startYearFetched = action.payload;
    },
    setEndYearFetched: (state, action) => {
      state.filters.endYearFetched = action.payload;
    },
    setPeerReviewedFetched: (state, action) => {
      state.filters.peerReviewedFetched = action.payload;
    },
    setGenerativeIAFetched: (state, action) => {
      state.filters.generativeIAFetched = action.payload;
    },
    setPageFetched: (state, action) => {
      state.filters.pageFetched = action.payload;
    },
    setAdvancedSearchFetched: (state, action) => {
      state.filters.advancedSearchFetched = action.payload;
    },
  },
});

export const {
  setLibrary,
  setTokenPage,
  resetLibrary,
  setIsLibraryLoading,
  setIsLibraryLoadingMoreData,
  setTotalData,
  setQueryFetched,
  setQueryAdvancedFetched,
  setPeerReviewed,
  setLibrarySources,
  resetLibrarySources,
  setSelectedSources,
  resetLibraryKeywords,
  setLibraryKeywords,
  setSelectedKeywords,
  setResetFilters,
  setStartYear,
  setEndYear,
  setSearchType,
  setGenerativeIAFetched,
  // Pagination
  setPageShowed,
  setAllLibrarySources,
  // Reset
  resetFilters,
  // Filtros para datos buscados
  setSourcesFetched,
  setKeywordsFetched,
  setStartYearFetched,
  setEndYearFetched,
  setPeerReviewedFetched,
  setPageFetched,
  setAdvancedSearchFetched,
} = librarySlice.actions;

export default librarySlice.reducer;
