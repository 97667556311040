// Utils
import { formatDate } from '../utils/formatDate';
import { fetchWithoutToken } from '../utils/fetch';
import { getEmailToSendInformation } from '../utils/getEmailToSendInformation';
// Service
import { getIpUser } from './getIpUser';

// Function to send user information to storage in data base
export const sendUserSearchInformation = async (
  title,
  resource_origin = '',
  url = '',
  resource_type,
  email,
  institutionCode
) => {
  const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

  if (ENVIRONMENT === 'PRODUCTION') {
    const date = new Date();
    const ip = await getIpUser();

    try {
      fetchWithoutToken.post(
        '/access-logs',
        {
          resource_type: resource_type
            ? resource_type.replace('/', '')
            : 'No resource type',
          resource_title: title ? title.slice(0, 250) : 'No title',
          resource_origin: resource_origin
            ? resource_origin.slice(0, 250)
            : 'No resource origin',
          url: url ? url.slice(0, 250) : 'No url',
          email: getEmailToSendInformation(email, institutionCode),
          ip,
          date: formatDate(date),
          institution_code: institutionCode,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      );
    } catch (e) {
      console.error(e);
    }
  } else {
    return null;
  }
};
