export const getEmailToSendInformation = (email, institutionCode) => {
  if (email) {
    switch (institutionCode) {
      case 'Carehope':
        return 'Care Hope student';
      case 'GAIAUNI':
        return 'Gaia Students';
      case 'ILUNI':
        return 'Iluni Students';
      default:
        return email.slice(0, 250);
    }
  } else {
    return 'No logged user';
  }
};
