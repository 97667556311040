import React, { useState } from 'react';
// Components
import Container from '../Container';
import MultiRangeSlider from '../MultiRangeSlider';
// Utils
import { areListsDiff } from '../../utils/areaListDiff';
import { currentYear } from '../../utils/getYearsFilters';
import { removeElement } from '../../utils/removeElement';
// Reddux
import { useSelector, useDispatch } from 'react-redux';
// Hooks
import { useLibrary } from '../../hooks/useLibrary';
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Slices
import {
  setStartYear,
  setEndYear,
  setSelectedSources,
  resetFilters,
  setPageShowed,
  setSelectedKeywords,
  setPeerReviewed,
} from '../../app/features/librarySlice';
// Icons
import { IoMdOptions } from 'react-icons/io';
import { TbChevronUp } from 'react-icons/tb';
import { IoMdClose } from 'react-icons/io';
// Styles
import './FiltersLibrary.css';

const FiltersLibrary = () => {
  const dispatch = useDispatch();

  const { getLibraryDataSocket } = useLibrary();
  const { handleScrollToTop } = useScrollToTop();

  const sourcesPeerReviewed = ['IEEE', 'ERIC', 'EUPMC', 'OPENALEX'];

  // State of filters
  const [isOpenSource, setIsOpenSource] = useState(false);
  const [isOpenYears, setIsOpenYears] = useState(false);
  const [isOpenKeywords, setIsOpenKeywords] = useState(false);

  const [activeFilter, setActiveFilter] = useState(false);

  // Función que cambia el estado de las variables startYear y endYear
  const yearSelected = (startYear, endYear) => {
    dispatch(setStartYear(startYear));
    dispatch(setEndYear(endYear));
  };

  const {
    selectedSources,
    selectedKeywords,
    startYear,
    endYear,
    libraryKeywords,
    totalData,
    filters,
    peerReviewed,
    queryFetched,
    queryAdvancedFetched,
    allLibrarySources,
    searchType,
    pageToken,
  } = useSelector((state) => state.library);

  const { institutionCode } = useSelector((state) => state.institution);

  // Función que selecciona las bases de datos
  const sourcesSelected = (optionValue) => {
    if (selectedSources.includes(optionValue)) {
      dispatch(
        setSelectedSources(
          selectedSources.filter((value) => value !== optionValue)
        )
      );
    } else {
      dispatch(setSelectedSources([...selectedSources, optionValue]));
    }
  };

  // Función que selecciona las keywords
  const keywordsSelected = (optionValue) => {
    if (selectedKeywords.includes(optionValue)) {
      dispatch(
        setSelectedKeywords(
          selectedKeywords.filter((value) => value !== optionValue)
        )
      );
    } else {
      dispatch(setSelectedKeywords([...selectedKeywords, optionValue]));
    }
  };

  const filterSourcePeerReviewed = (lista1, lista2) => {
    const set2 = new Set(lista2);
    return lista1.filter((elemento) => set2.has(elemento));
  };

  // Botón de Aplicar filtros
  const handleApplyFilters = (peerReviewed, start, end, sources, keywords) => {
    handleScrollToTop(0);
    dispatch(setPageShowed(1));
    getLibraryDataSocket(
      filters.advancedSearchFetched ? queryAdvancedFetched : queryFetched,
      1,
      peerReviewed,
      start,
      end,
      sources,
      keywords,
      searchType,
      pageToken
    );
  };

  // Funcion para Borrar filtros
  const handleClearFilters = () => {
    handleScrollToTop(0);
    dispatch(resetFilters());
    getLibraryDataSocket(
      filters.advancedSearchFetched ? queryAdvancedFetched : queryFetched,
      1,
      false,
      1990,
      currentYear,
      [],
      [],
      searchType,
      pageToken
    );
  };

  const setFilterPeerReviewed = () => {
    if (peerReviewed) {
      dispatch(setSelectedSources(allLibrarySources));
    } else {
      dispatch(setSelectedSources(sourcesPeerReviewed));
    }
    dispatch(setPeerReviewed(!peerReviewed));
  };

  return (
    <>
      <div className='filtersBarIcon'>
        <b>Filters</b>
        <button onClick={() => setActiveFilter(true)}>
          <IoMdOptions className='optionsIcon' />
        </button>
      </div>

      <section
        className={
          activeFilter ? 'sectionFilter activeFilter' : 'sectionFilter'
        }
      >
        <div
          onClick={() => setActiveFilter(false)}
          className={
            activeFilter
              ? 'filterBarBackground activeFilter'
              : 'filterBarBackground'
          }
        ></div>
        <div className='filtersBar'>
          <Container className='filtersBarMain'>
            <button onClick={() => setActiveFilter(false)}>
              <IoMdClose />
            </button>
            <div>
              <IoMdOptions className='optionsIcon' />
              <h2>Filters</h2>
            </div>
          </Container>

          {(filters?.endYearFetched !== currentYear ||
            filters?.startYearFetched !== 1990 ||
            filters?.peerReviewedFetched !== false ||
            areListsDiff(filters?.sourcesFetched, allLibrarySources)) && (
            <Container className='filtersBarSection'>
              <div className='filtersBarSection__main'>
                <h3>Filters</h3>
                <button onClick={handleClearFilters}>Remove all filters</button>
              </div>
              <div className='filtersBarSectionFilter__content'>
                {filters?.startYearFetched !== 1990 && (
                  <button
                    onClick={() =>
                      handleApplyFilters(
                        filters.peerReviewedFetched,
                        1990,
                        filters.endYearFetched,
                        filters.sourcesFetched,
                        filters.keywordsFetched
                      )
                    }
                  >
                    {`Start year: ${filters?.startYearFetched}`} <IoMdClose />
                  </button>
                )}

                {filters?.endYearFetched !== currentYear && (
                  <button
                    onClick={() =>
                      handleApplyFilters(
                        filters.peerReviewedFetched,
                        filters.startYearFetched,
                        currentYear,
                        filters.sourcesFetched,
                        filters.keywordsFetched
                      )
                    }
                  >
                    {`End year: ${filters?.endYearFetched}`}
                    <IoMdClose />
                  </button>
                )}

                {filters?.peerReviewedFetched === true && (
                  <button
                    onClick={() =>
                      handleApplyFilters(
                        false,
                        filters.startYearFetched,
                        filters.endYearFetched,
                        allLibrarySources,
                        filters.keywordsFetched
                      )
                    }
                  >
                    Peer reviewed <IoMdClose />
                  </button>
                )}
                {areListsDiff(filters?.sourcesFetched, allLibrarySources) &&
                  filters?.sourcesFetched?.map((source, i) => (
                    <button
                      key={i}
                      onClick={() =>
                        handleApplyFilters(
                          filters.peerReviewedFetched,
                          filters.startYearFetched,
                          filters.endYearFetched,
                          removeElement(filters.sourcesFetched, source).length >
                            0
                            ? removeElement(filters.sourcesFetched, source)
                            : allLibrarySources,
                          filters.keywordsFetched
                        )
                      }
                    >
                      {source.replace('_', ' ')} <IoMdClose />
                    </button>
                  ))}

                {filters?.keywordsFetched?.length > 0 &&
                  filters?.keywordsFetched?.map((keyword, i) => (
                    <button
                      key={i}
                      onClick={() =>
                        handleApplyFilters(
                          filters.peerReviewedFetched,
                          filters.startYearFetched,
                          filters.endYearFetched,
                          filters.sourcesFetched,
                          removeElement(filters.keywordsFetched, keyword)
                        )
                      }
                    >
                      {keyword} <IoMdClose />
                    </button>
                  ))}
              </div>
            </Container>
          )}

          <Container className='filtersBarSection'>
            <div className='filtersBarSection__main'>
              <label>
                {institutionCode === 'ILUNI' || institutionCode === 'NOUVELLE'
                  ? 'Revisión por pares'
                  : 'Peer reviewed'}
                <input
                  checked={peerReviewed ? true : false}
                  className='labelBox'
                  type='checkbox'
                  onChange={() => setFilterPeerReviewed()}
                />
              </label>
            </div>
          </Container>

          <Container className='filtersBarSection'>
            <div
              className='filtersBarSection__main'
              onClick={() => setIsOpenSource(!isOpenSource)}
              role='button'
            >
              <h3>
                {institutionCode === 'ILUNI' || institutionCode === 'NOUVELLE' ? 'Bases de datos' : 'Sources'}
              </h3>

              <button>
                <TbChevronUp className={isOpenSource ? '' : 'iconActive'} />
              </button>
            </div>
            {isOpenSource && (
              <div className='filtersBarSection__content fixed'>
                {allLibrarySources?.map((source, i) => {
                  return (
                    <button
                      key={i}
                      className={
                        selectedSources.includes(source) ? 'selected' : ''
                      }
                      onClick={() => sourcesSelected(source)}
                    >
                      {source.replace('_', ' ')}
                    </button>
                  );
                })}
              </div>
            )}
          </Container>

          <Container className='filtersBarSection'>
            <div
              className='filtersBarSection__main'
              onClick={() => setIsOpenYears(!isOpenYears)}
              role='button'
            >
              <h3>{institutionCode === 'ILUNI' || institutionCode === 'NOUVELLE' ? 'Año' : 'Year'}</h3>

              <button>
                <TbChevronUp className={isOpenYears ? '' : 'iconActive'} />
              </button>
            </div>
            {isOpenYears && (
              <div className='filtersBarSection__content'>
                <MultiRangeSlider
                  min={startYear}
                  max={endYear}
                  onChange={({ min, max }) => yearSelected(min, max)}
                />
              </div>
            )}
          </Container>

          {libraryKeywords.length > 0 && (
            <Container className='filtersBarSection'>
              <div
                className='filtersBarSection__main'
                onClick={() => setIsOpenKeywords(!isOpenKeywords)}
                role='button'
              >
                <h3>
                  {institutionCode === 'ILUNI' || institutionCode === 'NOUVELLE' ? 'Palabras clave' : 'Keywords'}
                </h3>

                <button>
                  <TbChevronUp className={isOpenKeywords ? '' : 'iconActive'} />
                </button>
              </div>
              {isOpenKeywords && (
                <div className='filtersBarSection__content fixed'>
                  {libraryKeywords?.slice(0, 30)?.map((keyword, i) => {
                    return (
                      <button
                        key={i}
                        className={
                          selectedKeywords.includes(keyword) ? 'selected' : ''
                        }
                        onClick={() => keywordsSelected(keyword)}
                      >
                        {keyword}
                      </button>
                    );
                  })}
                </div>
              )}
            </Container>
          )}

          {(peerReviewed !== filters.peerReviewedFetched ||
            areListsDiff(selectedKeywords, filters.keywordsFetched) ||
            startYear !== filters.startYearFetched ||
            endYear !== filters.endYearFetched ||
            areListsDiff(selectedSources, filters.sourcesFetched)) &&
            totalData > 0 && (
              <Container>
                <button
                  onClick={() =>
                    handleApplyFilters(
                      peerReviewed,
                      startYear,
                      endYear,
                      peerReviewed
                        ? filterSourcePeerReviewed(
                            sourcesPeerReviewed,
                            selectedSources
                          )
                        : selectedSources,
                      selectedKeywords
                    )
                  }
                  className='applyButton'
                >
                  Apply filters
                </button>
              </Container>
            )}
        </div>
      </section>
    </>
  );
};

export default FiltersLibrary;
